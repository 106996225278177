<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses"/>

    <!--  show event prompt  -->
    <custom-event ref="event"
                  :event-data="newEvents[0]"
                  @seen="showEvent(true)"/>

    <custom-context-menu ref="contextMenu" />

    <!-- show notification list prompt -->
    <vs-prompt
      class="small-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showNotificationListPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('showAllEventsBtn')">
              <custom-icon icon="EYE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('navbar.notification.newMessage') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showNotificationListPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <notification-list :events="newEvents" @empty="showNotificationListPromptStatus = false" />
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import themeConfig from '@/../themeConfig.js'
import CustomEvent from './components/customEvent/customEvent'
import {getProfile} from "./http/requests/profile";
import updateApp from './mixins/update'
import {getCompanyInfo} from "./http/requests/auth";
import CustomContextMenu from "./components/customContextMenu/customContextMenu";
import getLogs from "@/mixins/getLogs";
import store from "@/store/store";
import CustomIcon from "@/components/customIcon/customIcon.vue";
import NotificationList from "@/layouts/components/navbar/components/NotificationList.vue";

export default {
  components: {NotificationList, CustomIcon, CustomContextMenu, CustomEvent},
  metaInfo: {
    titleTemplate: (titleChunk) => {
      const base_title = localStorage.getItem('clubInfo') && JSON.parse(localStorage.getItem('clubInfo')).club_name ? JSON.parse(localStorage.getItem('clubInfo')).club_name : 'یوزرال | Useral'
      return titleChunk ? `${titleChunk} | ${base_title}` : base_title
    }
  },
  mixins: [updateApp, getLogs],
  data () {
    return {
      vueAppClasses: [],
      showNotificationListPromptStatus: false,
      pageHaveChanges: false,
      iconMapper: {
        1: 'PackageIcon',
        2: 'LayersIcon',
        3: 'MessageSquareIcon',
        4: 'AlertCircleIcon'
      },
      categoryMapper: {
        1: 'success',
        2: 'secondary',
        3: 'primary',
        4: 'warning'
      },
      newEvents: []
    }
  },
  watch: {
    updateExists: {
      handler (val) {
        if (val) {
          this.refreshApp()
        }
      }
    },
    '$store.state.pageHaveChanges' () {
      /*if (val) {
        window.addEventListener('beforeunload', function (e) {
          e.preventDefault();
          e.returnValue = '';
        });
      } else {
        window.removeEventListener('beforeunload', function (e) {
          e.preventDefault();
          e.returnValue = '';
        });
      }*/
    },
    '$store.state.auth.user': {
      handler (val) {
        if (val.events && val.events.length > 0) {
          const dynamicEventSubjectKeys = ['woo_controller_user_technical_problem',
            'woo_controller_product_variant_technical_problem', 'woo_controller_product_technical_problem',
            'woo_controller_invoice_technical_problem']
          this.newEvents = []
          val.events.forEach((event) => {
            let eventContent = {}
            try {
              eventContent = JSON.parse(event.event.content)
            }
            catch (e) {
              eventContent = event.event.content
            }
            let data = {
              index: this.newEvents.length,
              id: event.id,
              title: this.getLogSubject(event.event.subject, dynamicEventSubjectKeys.indexOf(event.event.subject) > -1 ? eventContent : {}),
              msg: event.event.content,
              icon: this.iconMapper[event.event.type],
              time: event.event.created_at,
              category: this.categoryMapper[event.event.type],
              event: event.event
            }

            if (typeof eventContent === 'object') {
              let contentLogs = []
              Object.values(eventContent.errors || eventContent).forEach((details) => {
                if (details.hasOwnProperty('key')) {
                  contentLogs.push(`${this.getErrorLogs(details)}`)
                } else {
                  const detail = {
                    ...eventContent,
                    key: event.event.subject
                  }
                  contentLogs.push(`${this.getErrorLogs(detail)}`)
                }
              })
              data.msg = contentLogs.join(', ')
            }

            data.event = {
              ...data.event,
              subject: data.title,
              content: data.msg
            }

            this.newEvents.push(data)
          })
          this.showEvent()
        }
      },
      deep: true
    },
    '$store.state.theme' (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
    '$route' () {
      this.showNotificationListPromptStatus = false
      if (localStorage.getItem('expired_at') && (new Date().getTime()) / 1000 > localStorage.getItem('expired_at')) {
        this.$store.dispatch('auth/logout')
        this.$vs.notify({
          title: this.$t('login.notification.forceLogout.title'),
          text: this.$t('login.notification.forceLogout.message'),
          color: 'danger',
          icon: 'icon-log-out',
          iconPack: 'feather',
          time: 2400
        })
      }
    }
  },
  methods: {
    toggleClassInBody (className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        if (document.body.className.match('theme-sea')) document.body.classList.remove('theme-sea')
        if (document.body.className.match('theme-flamingo')) document.body.classList.remove('theme-flamingo')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-sea')) document.body.classList.remove('theme-sea')
        if (document.body.className.match('theme-flamingo')) document.body.classList.remove('theme-flamingo')
        document.body.classList.add('theme-semi-dark')
      } else if (className === 'sea') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        if (document.body.className.match('theme-flamingo')) document.body.classList.remove('theme-flamingo')
        document.body.classList.add('theme-sea')
      } else if (className === 'flamingo') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        if (document.body.className.match('theme-sea')) document.body.classList.remove('theme-sea')
        document.body.classList.add('theme-flamingo')
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        if (document.body.className.match('theme-sea')) document.body.classList.remove('theme-sea')
        if (document.body.className.match('theme-flamingo')) document.body.classList.remove('theme-flamingo')
        document.body.classList.add('theme-light')
      }
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
    showEvent (remove = false) {
      const userInfo = this.$store.state.auth.user
      if (!(userInfo.active_sessions > userInfo.role.allowed_active_sessions)) {
        setTimeout(() => {
          if (remove) this.newEvents.splice(0, 1)

          if (this.newEvents.length === 1 && this.$refs.event) this.$refs.event.showEvent()
          else if (this.newEvents.length > 0) {
            this.showNotificationListPromptStatus = true
          }
        }, 500)
      }
    },
    setUserInfo () {
      getProfile().then((validate) => {
        if (validate.status === 200) {
          const profileInfo = validate.data.data
          const user = {
            permissions: profileInfo.permissions,
            financialYear: profileInfo.financial_year
          }
          localStorage.setItem('userInfo', JSON.stringify(user))
          this.$store.dispatch('auth/updateUserInfo', profileInfo)

          let tableSettings = JSON.parse(profileInfo.browser_setting)
          // delete tableSettings.tables.payload
          if (tableSettings) {
            this.$store.dispatch('table/setUserBrowserSettings', tableSettings)
          }
        } else {
          // localStorage.removeItem('accessToken')
          // location.reload()
        }
      }).catch(() => {
        // localStorage.removeItem('accessToken')
        // location.reload()
      })
    },
    getClubInfoSetting() {
      getCompanyInfo().then((response) => {
        const club = response.data
        const clubInfo = {
          club_name: club.company_name,
          club_logo: club.company_avatar ? this.$u.getAssetsUrl(club.company_avatar) : '',
          club_currency: club.club_currency || '',
          club_currency_code: club.club_currency_code || 'NUN',
          club_language: club.club_language || 'fa',
          club_locale: club.club_locale || 'IRI',
          club_default_country_code: club.club_default_country_code || ''
        }
        this.$store.dispatch('setting/setClubInfo', clubInfo)
        this.$u.locale.setLocale(club.club_language)
      })
    },
    uninstallServiceWorker () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (const registration of registrations) {
            // unregister service worker
            console.log('serviceWorker unregistered');
            registration.unregister();
          }
        });
      }
    },
    handleBeforeUnload (event) {
      if (this.$store.state.pageHaveChanges) {
        event.preventDefault()
        return event.returnValue = '';
      }
    }
  },
  mounted () {
    Vue.prototype.i18n = this.$i18n
    //  set current theme
    this.uninstallServiceWorker()
    const theme = {
      value: '',
      sendServer: false
    }
    theme.value = localStorage.getItem('theme')
    if (theme.value) {
      this.$store.dispatch('updateTheme', theme)
    }
    document.body.style.fontFamily = this.$store.state.locale.fonts

    // set current locale
    if (this.locale.language) {
      this.$i18n.locale = this.locale.language
    }
    this.$vs.rtl = this.locale.dir === 'rtl'
    // /set current locale

    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    if (localStorage.accessToken) {
      this.setUserInfo()
    }

    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  computed: {
    ...mapGetters({
      locale: 'currentLocale'
    })
  },
  async created () {
    this.$u.locale.setDefaultLocale()
    await this.getClubInfoSetting()
    Vue.prototype.$vs = this.$vs
    Vue.prototype.noAccessNotify = {
      title: this.$t('alert.error.title'),
      text: this.$t('alert.error.accessDenied')
    }
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

    /*let activeUser = this.$store.state.auth.user
    if (activeUser) {
      // get data from pusher
      if (!window.Echo.connector.pusher.config.auth.headers['Authorization'])
        window.Echo.connector.pusher.config.auth.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
      window.Echo.private("employee-notif").listen(".all", (e) => {
        if (e.message) {
          this.$vs.notify({
            title: 'پیام',
            text: e.message,
            time: 5000,
            icon: 'icon-message-circle',
            iconPack: 'feather'
          })
        } else {
          console.log(e);
        }
      });
    }*/
  },
  beforeDestroy () {
    this.$u.locale.setDefaultLocale()
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>

<style lang="scss">
.vs-sidebar {
  /*background-color: #1976d2 !important;*/
}
</style>
