export default {
  data () {
    return {
      locales: {
        IRI: {
          timezone: '+0330',
          locale: 'fa'
        },
        UAE: {
          timezone: '+0400',
          locale: 'en'
        }
      }
    }
  },
  methods: {
    getLocale: function (region) {
      return this.locales[region].locale
    },
    getTimezone: function (region) {
      return this.locales[region].timezone
    }
  }
}
